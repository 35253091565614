import react from 'react';










const Privacy = () => {

  return (
    <>
      <section className="banner-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner-title">
                  <h1>Privacy Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-area pt-80 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-12 privacy-policy">

                <p>
                  This privacy policy applies to https://FDyay.com and FDyay! Consulting and outlines data collection and use. The FDyay! Website is a Business site. By using the FDyay! Website, you consent to the data practices described in this policy statement.
                </p>

                <h5>Collection of your Personal Information</h5>

                <p>In order to provide you with products and services offered, you may elect to fill out a form that requests your</p>

                <ul>
                  <li>First and Last Name</li>
                  <li>Mailing Address</li>
                  <li>E-mail Address</li>
                  <li>Phone Number</li>
                  <li>Employer</li>
                  <li>Job Title</li>
                  <li>Type of Manufacturer of Products Produced</li>
                </ul>

                <br />

                <p>
                  This information will be used solely for our consultants to gain scope and recognition of who you are and what your needs might be prior to using the information to contact you.
                </p>

                <p>
                  In addition, if you contract with FDyay! Consulting or purchase our services, we collect billing and credit card information solely to complete the purchase transaction.
                </p>

                <p>
                  We do not collect personal information about you unless you elect to provide it to us. We use this information to get to know you as a customer. We will never sell, rent, or lease your personal information to anyone. FDyay! May share data with trusted partners to help improve product and service performance, end you email or postal mail, provide customer support, or arrange for plant visits. All such partners are prohibited from using your information for any purpose except to provide these services to FDyay!, and they are required to maintain the confidentiality of your information.
                </p>

                <p>
                  FDyay! May disclose your personal information, without notice, if required to do so by law or to protect or defend the rights of FDyay!; and/or act on behalf of the public or users of FDyay! to protect their personal safety.
                </p>

                <h5>Automatically Collected Information</h5>

                <p>
                  Information about your computer, such as your IP address, browser type, domain name, access times, and referring website addresses, only for the operation of the service, to maintain quality of service, and to generate statistics regarding the use of FDyay! website.
                </p>

                <p>
                  FDyay! uses SSL/TLS protocol to secure your personal information from unauthorized access, use, or disclosure. Personal information such as credit card numbers are encrypted. Though we strive
                  to protect your information, no data transmission over the Internet can be guaranteed be 100% secure. Therefore, you acknowledge that there are security and privacy limitations inherent to the Internet which are beyond our control and that the integrity, security, and privacy of any information transmitted to us cannot be guaranteed.
                </p>

                <h5>Right to Deletion</h5>

                <p>
                  On verbal or written request from you, we will delete all information from our records and require that any trusted partners do likewise, unless it is necessary to:
                </p>

                <ul>
                  <li>Complete the transaction for which the information was collected</li>
                  <li>Detect security incidents, protect against fraudulent or illegal activity</li>
                  <li>Debug to identify and repair errors</li>
                  <li>Comply with existing legal obligations</li>
                  <li>Internal, lawful uses</li>
                </ul>

                <br />

                <h5>Children Under 13</h5>

                <p>
                  Children under the age of 13 are not expected to use this website. FDyay! does not knowingly collect personal information from children under the age of 13. If you are under the age of 13, don’t use this website.
                </p>

                <h5>E-mail communications</h5>

                <p>
                  FDyay! may contact you via email to provide information, announcements, or promotional offers, confirmations, surveys, and other general communication. You can always opt out by replying “UNSUBSCRIBE” to such emails.
                </p>

                <p>
                  FDyay! reserves the right to make changes to this policy from time to time and will notify you of the changes using your preferred mode of contact or email, whichever is more convenient.
                </p>

                <p>
                  If for any reason you believe that FDyay! has not adhered to this Privacy Policy, feel free to contact us at any time.
                </p>

                <p>Email: sayhello@fdyay.com</p>
                <p>Telephone: (801) 574-3949</p>
                <p>Effective: April 15, 2022</p>

              </div>
            </div>
          </div>
        </section>

      </>
  )
}

export default Privacy;