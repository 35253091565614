import react from 'react';
import CountUp from 'react-countup';

const CounterArea = () => {

  return (
    //  {/* <!-- counter area --> */}
    <section className="counter-area pt-65 pb-30">
        <div className="container">
            {/* <!-- section title --> */}
            <div className="row">
                <div className="col-12 text-center">
                    <div className="section-title " data-aos="fade-down">
                        <h3>Benefits of FDYAY</h3>
                    </div>
                </div>
            </div>

            <div className="row">
                {/* <!-- single counter --> */}
                <div className="col-md-4 text-center">
                    <div className="single-counter " data-aos="fade-right">
                        <div className="counter-text">
                            <p>Over</p>
                        </div>
                        <div className="counter-amount">
                            <p><CountUp start={0} end={20} /></p>
                        </div>
                        <div className="counter-text">
                            <p>years of experience</p>
                        </div>
                    </div>
                </div>
                {/* <!-- single counter end --> */}
                {/* <!-- single counter --> */}
                <div className="col-md-4 text-center">
                    <div className="single-counter " data-aos="fade-up">
                        <div className="counter-text">
                            <p>Over</p>
                        </div>
                        <div className="counter-amount">
                            <p><CountUp start={0} end={500} prefix="$" suffix="K"/></p>
                        </div>
                        <div className="counter-text">
                            <p>in realized R.O.I. annually*</p>
                        </div>
                    </div>
                </div>
                {/* <!-- single counter end --> */}
                {/* <!-- single counter --> */}
                <div className="col-md-4 text-center">
                    <div className="single-counter " data-aos="fade-down">
                        <div className="counter-text">
                            <p>Over</p>
                        </div>
                        <div className="counter-amount">
                            <p><CountUp start={0} end={866} /></p>
                        </div>
                        <div className="counter-text">
                            <p>audits and inspections</p>
                        </div>
                    </div>
                </div>
                {/* <!-- single counter end --> */}
                {/* <!-- single counter --> */}
                {/* <div className="col-md-3 text-center">
                    <div className="single-counter no-border" data-aos="fade-left">
                        <div className="counter-text">
                            <p>Over</p>
                        </div>
                        <div className="counter-amount">
                            <p><CountUp start={0} end={19} /></p>
                        </div>
                        <div className="counter-text">
                            <p>companies</p>
                        </div>
                    </div>
                </div> */}
                {/* <!-- single counter end --> */}
            </div>
            <div className="row">
                <div className="col-12 ">
                  <p>* Highly dependent on the scope, scale of work and size of manufacturer.</p>
                </div>
            </div>
        </div>
    </section>
  //{/* <!-- counter area end --> */}
  );
}

export default CounterArea;