import React, { useState, useContext } from 'react';
import { LoadingContext } from '../components/LoadingContext';

const EmailSent = () => {

  return (
    <div className="container">
    <div className="row">
        <div className="col-md-12 text-center">
          <h2>Email Sent!</h2>
        </div>
      </div>
    </div>
  );
}

const ContactUsArea = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage ] = useState('');

  const [informationMissing, setInformationMissing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const loadingContext = useContext(LoadingContext);

  const sendMessage = (event) => {
    
    event.preventDefault(); // Prevents Page reload after submit
    if (!name || !email || !phonenumber || !subject || !message ) {
      setInformationMissing(true);
      return;
    }

    loadingContext.setIsLoading(true);
    //Post Data to server to email.
    fetch('/mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name, email, phonenumber, subject, message})
    })
    .then(response => {
      console.log(response.ok);
      setEmailSent(true);
      loadingContext.setIsLoading(false);
    });
 
  }

  return (
    //  {/* <!-- contact us section --> */}
    <div id="contactus" className="contact_wrap mt-5">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="contact_area">
                        {/* <!-- section title --> */}
                        <div className="section-title " data-aos="fade-down">
                            <h6>Contact US</h6>
                            <h4>Get in touch with us</h4>
                        </div>
                        {/* <!-- end of section title --> */}

                        {/* <!-- contact form --> */}
                        {!emailSent && <form className="contact_form " data-aos="fade-down" onSubmit={sendMessage} >
                            <div className="row">
                                <div className="col-md-6 col-lg-3">
                                    <label htmlFor="input_name">Name<i className="fas fa-asterisk"></i></label>
                                    <input type="text" placeholder="Enter your name" id="input_name" pattern="[a-zA-Z\s]+" onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <label htmlFor="input_email">E-mail<i className="fas fa-asterisk"></i></label>
                                    <input type="email" placeholder="youremail@email.com" id="input_email"
                                          onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <label htmlFor="input_number">Phone<i className="fas fa-asterisk"></i></label>
                                    <input type="text" placeholder="XXX-XXX-XXXX" id="input_number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" maxLength="12"
                                          onChange={e => setPhonenumber(e.target.value)} />
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <label htmlFor="input_subject">Subject<i className="fas fa-asterisk"></i></label>
                                    <input type="text" placeholder="subject" id="input_subject" pattern="[a-zA-Z0-9\s]+" onChange={e => setSubject(e.target.value)} />
                                </div>
                            </div>
                            <div className="from-row">
                                <div className="col-md-12">
                                    <label htmlFor="input_message">Message<i className="fas fa-asterisk"></i></label>
                                    <input type="text" placeholder="type in your message" id="input_message" pattern="[a-zA-Z0-9,.\s]+"
                                          onChange={e => setMessage(e.target.value)} />
                                </div>
                            </div>
                            <button type="submit" id="submit_btn" className="submit_btn float-sm-left float-md-right p-3">Send Message</button>
                            { informationMissing && <span id="form_notice" className="float-lg-left float-left mt-3 mb-2 w-75">Missing Required Information</span> }
                        </form> }
                        {emailSent && <EmailSent />}
                        {/* <!-- end of contact form --> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
//{/* <!-- contact us section --> */}

  );
}

export default ContactUsArea;