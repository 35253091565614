import React, { useState, useEffect, useContext } from 'react';
import blogImage1 from '../assets/img/blog/blog--training-1.jpg';
import LoadingOverlay from '../components/LoadingOverlay';
import { LoadingContext } from '../components/LoadingContext';


//The registration section is utilizing the blog template
const Training = () => {

  const loadingContext = useContext(LoadingContext);
  const [trainingClasses, setTrainingClasses] = useState([]);

  useEffect(() => {

    window
      .fetch("/api/training", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(new Date(data[2].date).getFullYear());
        setTrainingClasses(data);
      });
  }, []);

  const gnenerateContentList = (contents) => {
    var html = (contents.map(content => 
      <li className="mb-10">{content}</li>
    ));
    return (html);
  }

  const generateClassesHtml = () => {
    
    var html = trainingClasses.map(trainingClass => 
      <div key={trainingClass.id} className="single-blog-text mb-50">
        <div className="single-blog-meta">
          {/* <span>{ new Date(trainingClass.date).getFullYear() === 0 ? 'Not Scheduled' : new Date(trainingClass.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</span>  */}
          <span>Presenter: {trainingClass.presenter}</span>
         <a style={{ color: '#0284c7'}}>${trainingClass.price} per person</a>
        </div>
        <h4>{trainingClass.title}</h4>
        <p>{trainingClass.description}</p>
        <h5>Covers and Includes</h5>
        <ul className="">
          {gnenerateContentList(trainingClass.contents)}
        </ul>
     
        {/* <div className="single-blog-footer d-flex justify-content-between align-items-center">
          <a href={process.env.PUBLIC_URL + `/register/${trainingClass.id}`} className="read-more-btn" >Register</a>
        </div>11 */}

        <div className="single-blog-footer d-flex justify-content-between align-items-center">
          <p style={{ color: '#0284c7 '}}>Call <b>801-574-3949</b> to Schedule Training or for more information</p>
        </div>
        
      </div>
    );
    return (html);
  }

  return(
    <>
      { loadingContext && loadingContext.isLoading && <LoadingOverlay /> }
      {/* <!-- banner area --> */}
      <section className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-title">
                <h1>WE BRING THE TRAINING TO YOU</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area pt-80 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="blog-content">
                {/* <!-- single blog --> */}
                <div className="single-blog-wrapper mb-50">
                  {/* <!-- blog slider  --> */}
                  <div className="single-blog-slider mb-25">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="blog-slider">
                          {/* <!-- single blog slider --> */}
                          <div className="single-blog-slider">
                            <div className="blog-slider-img">
                              <img src={blogImage1} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  { generateClassesHtml() }

                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="blog-aside">
                  <div className="aside-popular-cetagories mb-50">
                    <h6 className="aside-section-title">Classes</h6>
                    <p>Here you will find information regarding our upcomming training classes.</p>
                    <p>
                      Trainings are periodically scheduled at various locations throughout Utah. Contact us today to have a trainer come train your team at your facility!
                    </p>
                    <p>If you have any further questions regarding these classes please call us at <span>801-574-3949</span></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default Training;
