import react from 'react';
import { Carousel } from 'react-responsive-carousel';

const CarouselArea = () => {

  return (
    //  {/* <!-- slider area --> */}
    <section className="slider-area">
        
        <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} showThumbs={false}>
            {/* <!-- single slider --> */}
            <div className="single-slider">
                <div className="container">
                    <div className="row">
                        <div className="col-9">
                            <div className="slider-title">
                                <h2>Grow Your Business</h2>
                                <h1>Quality Assurance and Regulatory Consulting</h1>
                                <p>Check out all the ways we can help!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- single slider-end --> */}
            {/* <!-- single slider --> */}
            <div className="single-slider single-slider-2">
                <div className="container">
                    <div className="row">
                        <div className="col-9 offset-3 text-center">
                            <div className="slider-title">
                                <h2>We Provide</h2>
                                <h1>Creative Quality &amp; Regulatory Solutions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- single slider-end --> */}
            </Carousel>
        
    </section>
    // {/* <!-- slider area end --> */}

  );
}

export default CarouselArea;