import react from 'react';


const MapSectionArea = () => {

  return (
    // {/* <!-- map section --> */}
    <div className="map">
        <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d96765.68402974261!2d-111.89450796989424!3d40.719609709777636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1646532737644!5m2!1sen!2sus"
                allowFullScreen></iframe>
    </div>
    //{/* <!-- map section --> */}
  );
}

export default MapSectionArea;