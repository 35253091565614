import React, { useState, useEffect, useContext } from 'react';
import RegistrationForm from '../components/RegistrationForm';
import LoadingOverlay from '../components/LoadingOverlay';
import { LoadingContext } from '../components/LoadingContext';


//The registration section is utilizing the blog template
const Registration = ({ match }) => {

  const loadingContext = useContext(LoadingContext);
  const trainingId = match.params.trainingId;
  const [productIds] = useState([trainingId]); 
  const [trainingClasses, setTrainingClasses] = useState([]);


  useEffect(() => {

    window
      .fetch("/api/training", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setTrainingClasses(data);
      });
  }, []);

  return(
    <>
      { loadingContext && loadingContext.isLoading && <LoadingOverlay /> }
      {/* <!-- banner area --> */}
      <section className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-title">
                <h1>Registration</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      { trainingClasses.length > 0 && <section className="blog-area pt-80 pb-60">
        <div className="container">
          <div className="row">
          <div className="col-12">
             <h4>{trainingClasses.filter(x => x.id === trainingId)[0].title}</h4>
             <h4>{new Date(trainingClasses.filter(x => x.id === trainingId)[0].date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</h4>
            <RegistrationForm productIds={productIds}/>
          </div>
          </div>
        </div>  
      </section>
      }
      
    </>
  );
}

export default Registration;
