import react from 'react';
import logo from '../assets/img/logo.png';
import MobileMenu from './MobileMenu';

const HeaderArea = () => {

  return(
    // {/* <!-- Header area started --> */}
    <header>
      <div className="container">
          <div className="row align-items-center">
              {/* <!-- header logo --> */}
              <div className="col-md-2">
                  <div className="header-logo">
                      <a href={process.env.PUBLIC_URL + '/'}><img src={logo} alt="Logo" /></a>
                  </div>
              </div>
              {/* <!-- navbar --> */}
              <div className="col-md-10 col-lg-9 col-xl-8 offset-xl-2 offset-lg-1 offset-0 d-none d-md-block">
                  <nav>
                      <ul className="d-flex justify-content-end align-items-center">
                          <li><a href={process.env.PUBLIC_URL + '/'} className="link-hover">Home</a></li>
                          <li className="with-submenu"><a className="link-hover" href={process.env.PUBLIC_URL + '/services'}>Services</a>
                              {/* <ul className="sub-menu">
                                  <li><a href="/consulting">Consulting</a></li>
                                  <li><a href="/auditing">Auditing</a></li>
                                  <li><a href="/training">Training</a></li>
                                  <li><a href="/documentation">Documentation</a></li>
                              </ul> */}
                          </li>
                          <li className="with-submenu"><a className="link-hover" href={process.env.PUBLIC_URL + '/training'}>Training</a></li>
                          {/* <li><a className="link-hover" href="about.html">About</a></li> */}
                          <li><a className="link-hover" href="#contactus">Contact Us</a></li>
                      </ul>
                  </nav>
              </div>
          </div>
          {/* <!-- mobile menu --> */}
          {/* <div className="sidebar-icon d-block d-md-none">
              <a href="#" id="open-sidebar"><img src={mobileMenuBar} alt="" /></a>
          </div> */}
          <MobileMenu />
      </div>
    </header>
// {/* <!-- header area end --> */ }
  );
}

export default HeaderArea;