import React, { useContext } from 'react';
import CarouselArea from '../components/CarouselArea.js';
import AboutUsArea from '../components/AboutUsArea.js';
import VideoArea from '../components/VideoArea.js';
import CounterArea from '../components/CounterArea';
import WorkArea from '../components/WorkArea.js';
import TeamArea, { OurApproach } from '../components/TeamArea.js';
import BrandArea from '../components/BrandArea.js';
import TestimonialArea from '../components/TestimonialArea.js';
import ContactUsArea from '../components/ContactUsArea.js';
import MapSectionArea from '../components/MapSectionArea.js';

import LoadingOverlay from '../components/LoadingOverlay';
import { LoadingContext } from '../components/LoadingContext';

 const Home = () => {

    const loadingContext = useContext(LoadingContext);
    return (
      <>
        { loadingContext && loadingContext.isLoading && <LoadingOverlay /> }
        <CarouselArea />
        <AboutUsArea />
        <VideoArea />
        <CounterArea />
        <WorkArea />
        {/* <TestimonialArea /> */}
        <OurApproach />
        {/* <TeamArea /> */}
        {/* <BrandArea /> */}
        <ContactUsArea />
        <MapSectionArea />
       
      </>
    );
  }

export default Home;