import react from 'react';

import aboutImage1 from '../assets/img/about/about-img-1.png';
import aboutImage2 from '../assets/img/about/about-img-2.png';
import aboutImage3 from '../assets/img/about/about-img-3.png';

const AboutUsArea = () => {

  return (
    // {/* <!-- about area --> */}
    <section className="about-area pb-65">
        <div className="container">
            <div className="row">
                {/* <!-- about wrapepr --> */}
                <div className="col-12">
                    <div className="about-wrapper">
                        <div className="row">
                            {/* <!-- single about wrapper --> */}
                            <div className="col-lg-4 text-center">
                                <div className="single-about">
                                    <div className="about-img">
                                        <img src={aboutImage1} alt="" />
                                    </div>
                                    <div className="about-content">
                                        <h6>We are creative</h6>
                                        <p>Problem Solving; Complaints Resolution; Cost Savings; Internal Audits, Risk Assessments, Procedures, Processes, Forms and more!</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- single about end --> */}
                            {/* <!-- single about wrapper --> */}
                            <div className="col-lg-4 text-center">
                                <div className="single-about">
                                    <div className="about-img">
                                        <img src={aboutImage2} alt="" />
                                    </div>
                                    <div className="about-content">
                                        <h6>We Think Like You Do</h6>
                                        <p>
                                          We get it. You need fiscally responsible solutions in no time with real, lasting and measurable results.
                                          With 20+ years in the business, we're keen to provide solutions. Today.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- single about end --> */}
                            {/* <!-- single about wrapper --> */}
                            <div className="col-lg-4 text-center">
                                <div className="single-about">
                                    <div className="about-img">
                                        <img src={aboutImage3} alt="" />
                                    </div>
                                    <div className="about-content">
                                        <h6>We are fast</h6>
                                        <p>
                                          You don't have weeks to wait. You have an audit coming up and you need solutions fast. We're here for you, to
                                          work on your schedule. Audit readiness and support a phone call away!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- single about end --> */}

                        </div>
                    </div>
                    {/* <!-- about wrappekr end --> */}
                </div>
            </div>
        </div>
    </section>
    // {/* <!-- aobut area end --> */}
  );
}

export default AboutUsArea;