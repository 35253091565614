import react from "react";

import workImage1 from '../assets/img/work/work-img-1.jpg';
import workImage2 from '../assets/img/work/work-img-2.jpg';
import workImage3 from '../assets/img/work/work-img-3.jpg';
import workImage4 from '../assets/img/work/work-img-4.jpg';
import workImage5 from '../assets/img/work/work-img-5.jpg';
import workImage6 from '../assets/img/work/work-img-6.jpg';

const WorkArea = () => {


  return (
    //  {/* <!-- work area --> */}
    <section className="work-area work-area-bg pt-80 pb-60">
        {/* <div className="work-area-bg"></div> */}
        <div className="container">
            {/* <!-- work text --> */}
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="work-left " data-aos="fade-right">
                        <h6>our mission</h6>
                        <h4>Growth<br />
                            Through<br />
                            Simplification
                        </h4>
                        {/* <!-- portfolio menu --> */}
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="portfolio-menu">
                                    <button className="active" data-filter="*">ALL</button>
                                    <button data-filter=".cat-2">QMS</button>
                                    <button data-filter=".cat-3">FSP</button>
                                    <button data-filter=".cat-4">HACCP</button>
                                    <button data-filter=".cat-5">AUDIT ASSESMENTS</button>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- portfolio menu end --> */}
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="work-right " data-aos="fade-left">
                        <p>We strive to help manufacturers of food and dietary supplements to efficiently and cost-effectively meet and exceed the requirements for their certifications and inspections. FDA, UDAF, NSF, GFSI, SQF, ISO 22000, Kosher, Halal, nGMO, Organic, IP</p>
                    </div>
                </div>
            </div>
            {/* <!-- work text end --> */}
            
        </div>
    </section>
    //{/* <!-- work area end --> */}

  );
}

export default WorkArea;