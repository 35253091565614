import react from 'react';



const VideoArea = () => {

  return (
    //{/* <!-- video area --> */}
    <section className="video-area pt-65 pb-30">
        <div className="container">
            <div className="row">
                {/* <!-- video text --> */}
                <div className="col-lg-12 mb-30">
                    <div className="video-text" data-aos="fade-right">
                        <p>what we do</p>
                        <h2>We find solutions to <br />
                            your manufacturing challenges.</h2>
                        <div className="happy-client-box">
                            <p>“ Helping small and ultra small companies to thrive and be competitive. ”</p>
                            <div className="happy-client-footer d-flex">
                                <p className="client-amount">1 to 2</p>
                                <p>weeks or less turn around.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- video text end --> */}
                {/* <!-- video iframe area --> */}
                {/* <div className="col-lg-6 mb-30">
                    <div className="video-wrapper" data-aos="fade-left">
                        <iframe src="https://www.youtube.com/embed/3Nh10jgces8" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div> */}
                {/* <!-- video iframe end --> */}
            </div>
        </div>
    </section>
    // {/* <!-- video area end --> */}
  );
}

export default VideoArea;