import React, { useState } from 'react';

export const LoadingContext = React.createContext();

export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const value = {
    isLoading,
    setIsLoading,
    hasError,
    setHasError
  };

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};