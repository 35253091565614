import React from "react";
import Spinner from "react-spinkit";
import styled from 'styled-components';

const Overlay = styled.div`
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.7);
  flex-direction: column;
`;

const SpinnerWrapper = styled.div`
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingOverlay = ({ loading, message }) => {
  return (
    <Overlay>
      <SpinnerWrapper>
        <Spinner name="ball-spin-fade-loader" fadeIn="none" color="#1F4A65" />
        <span className="message">{message}</span>
      </SpinnerWrapper>
    </Overlay>
  );
};

export default LoadingOverlay;
