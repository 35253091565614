import react from 'react';



const Services = () => {

  return (
    <>
       {/* <!-- banner area --> */}
      <section className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-title">
                <h1>Our Services</h1>
              </div>
            </div>
          </div>
        </div>
      </section> 

      <section className="service-section-wrapper">
      <div className="container ">
          <div className="row justify-content-center text-center ">
              <div className="col-lg-8 col-xl-6">
                  {/* <!-- section title --> */}
                  <div className="section-title">
                      <h2>Choose from one or many of the services we offer.</h2>
                      <p>
                        We work to meet our client's expectation. We tailor our services to meet the client's specific
                        needs. 
                      </p>
                  </div>
              </div>
          </div>
          <div className="row text-center ">
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-industry"></i></span>
                      {/* <!-- service content --> */}
                      <h3>MANUFACTURING SOLUTIONS</h3>
                      <p>Pain points resolution, scrap reduction, problem solving </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-pen"></i></span>
                      {/* <!-- service content --> */}
                      <h3>REGULATORY COMPLIANCE</h3>
                      <p>USFDA, EU, China, India, Japan, Mexico, Canada, and more!</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-clipboard-check"></i></span>
                      {/* <!-- service content --> */}
                      <h3>CERTIFICATIONS AND AUDITS</h3>
                      <p>cGLP, ISO, CFR, SQF, GFSI, PCQI, AIB, NSF, to name a few!</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-signature"></i></span>
                      {/* <!-- service content --> */}
                      <h3>SOP WRITING &amp; IMPLEMENTATION</h3>
                      <p>Tailored to your business, processes, people, and products</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-boxes"></i></span>
                      {/* <!-- service content --> */}
                      <h3>SUPPLIER QUALIFICATIONS</h3>
                      <p>Everything you need to purchase from your suppliers and manage through emergent stock issues</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-dolly"></i></span>
                      {/* <!-- service content --> */}
                      <h3>RAW MATERIAL QUALIFICATIONS</h3>
                      <p>A big part of cost savings is knowing what to test and what is overkill.</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-graduation-cap"></i></span>
                      {/* <!-- service content --> */}
                      <h3>TRAINING</h3>
                      <p>PCQI, cGMP, Food Safety, Food Defense, Food Fraud, Sanitation and your own SOPs</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-vials"></i></span>
                      {/* <!-- service content --> */}
                      <h3>LABORATORY DESIGN AND IMPLEMENTATION</h3>
                      <p>Want to bring your lab testing in house? We can help you decide what instruments you need and don’t need, what tests to run, and how</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-clipboard-list"></i></span>
                      {/* <!-- service content --> */}
                      <h3>ANALYTICAL TESTING AND SPECIFICATIONS</h3>
                      <p>Do you know what to test? What should the limits be? How can you save millions in testing?</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-allergies"></i></span>
                      {/* <!-- service content --> */}
                      <h3>ALLERGEN CONTROL PROGRAM</h3>
                      <p>Allergen segregation is tough to do with limited space. We have solutions to your puzzle for where to put things and how to manage allergen control throughout the facility </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-warehouse"></i></span>
                      {/* <!-- service content --> */}
                      <h3>SHELF LIFE STABILITY TEST PROGRAM</h3>
                      <p>What is required, what is overkill, and what to do when results aren’t what you expected</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-clipboard-check"></i></span>
                      {/* <!-- service content --> */}
                      <h3>PURCHASING AND QC SPECIFICATIONS</h3>
                      <p>Ensure that the quality specifications that Purchasing has are beneficial for all interests within the organization and for the consumer</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-tree"></i></span>
                      {/* <!-- service content --> */}
                      <h3>ENVIRONMENTAL MONITORING</h3>
                      <p>How many swabs? How often? What to test for? Where to test? What to do about the results? </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fab fa-stack-exchange"></i></span>
                      {/* <!-- service content --> */}
                      <h3>PRE-AUDIT ASSESMENTS</h3>
                      <p>We’ll let you know before your audit what the auditor will likely cite you for so that you can be sure to do your best on audit day! </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-bolt"></i></span>
                      {/* <!-- service content --> */}
                      <h3>RISK ASSESMENTS / HACCP / FOOD SAFETY PLANS</h3>
                      <p>It is required to have a separate Food Safety Plan for each unique product type. We can help. </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-broom"></i></span>
                      {/* <!-- service content --> */}
                      <h3>MASTER SANITATION SCHEDULE </h3>
                      <p>What to clean, how to clean, when to clean, how often, and how to document it. </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-dumpster"></i></span>
                      {/* <!-- service content --> */}
                      <h3>SCRAP/WASTE REDUCTION</h3>
                      <p>Application of LEAN, 6 Sigma, 5S and practical approaches to increase productivity</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-check-circle"></i></span>
                      {/* <!-- service content --> */}
                      <h3>COMPLAINTS RESOLUTION</h3>
                      <p>Serious Adverse Events Reports, customer complaints, failed test results—we have solutions! </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-check-double"></i></span>
                      {/* <!-- service content --> */}
                      <h3>CAPA/CAR</h3>
                      <p>Corrective and Preventive Actions ideation, implementation, and follow-up </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-file-image"></i></span>
                      {/* <!-- service content --> */}
                      <h3>cGMP</h3>
                      <p>English and Spanish training in all areas of the facility tailored to all employees and departments </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
              {/* <!-- single service --> */}
              <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-folder"></i></span>
                      {/* <!-- service content --> */}
                      <h3>MASTER MANUFACTURING &amp; BATCH RECORDS DESIGN AND IMPLEMENTATION</h3>
                      <p>Are you capturing enough information? Is it clear and readily understood on the plant floor? Are they pencil-whipping? Creative solutions at the ready! </p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
               {/* <!-- single service --> */}
               <div className="col-md-6 col-lg-4">
                  <div className="single-service">
                      <a href="#" className="link-overly"><span className="d-none">overly link</span></a>
                      {/* <!-- service icon --> */}
                      <span><i className="fas fa-tasks"></i></span>
                      {/* <!-- service content --> */}
                      <h3>Foreign Supplier Verification Programs</h3>
                      <p>Also known as FSVP, this rule requires importers to perform risk-based supplier verification of raw materials</p>
                  </div>
              </div>
              {/* <!-- single service end --> */}
          </div>
      </div>
    </section>
  </>
  );
}

export default Services;