import React, { useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router';
import Aos from 'aos';
import { LoadingContextProvider } from './components/LoadingContext';

import HeaderArea from './components/HeaderArea';
import FooterArea from './components/FooterArea';
import Home from './routes/Home';
import Services from './routes/Services';
import Registration from './routes/Registration';
import Training from './routes/Training';
import Privacy from './routes/Privacy';

const App = () => {
  
  useEffect(() => {
    Aos.init({
      delay: 200,
      duration: 1000
    });
  }, []);

 
  
  return (
    <LoadingContextProvider>
    <HeaderArea />
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/services' component={Services} />
      <Route exact path='/training' component={Training} />
      <Route exact path='/privacy' component={Privacy} />
      <Route path='/register/:trainingId' component={Registration} />
    </Switch>
    <FooterArea />
    </LoadingContextProvider>
  );
}

export default App;