import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import teamImage1 from "../assets/img/team/team-img-1.jpg";
import teamImage2 from "../assets/img/team/team-img-2.jpg";
import teamImage3 from "../assets/img/team/team-img-3.jpg";
import teamImage4 from "../assets/img/team/team-img-4.jpg";
import teamImage5 from "../assets/img/team/team-img-5.jpg";
import teamImage6 from "../assets/img/team/team-img-6.jpg";
import teamImage7 from "../assets/img/team/team-img-7.jpg";


const TeamArea = () => {

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);
  
  const settingsPictures = {
   
    infinite: true,
    centerMode: true,
    autoPlay: true,
    slidesToShow: 5,
    swipeToSlide: true,
    dots:true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  }

  const settingsTitles = {
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    centerMode: true,
    arrows: false,
    focusOnSelect: true,
    
  }


  return (
    //{ /* <!-- team area --> */}
    <section className="team-area pt-65 pb-60">
        <div className="container">
            {/* <!-- section title  --> */}
            <div className="section-title text-left " data-aos="fade-up">
                <h6>meet the team</h6>
                <h3>Amazing and talented <br />
                    people work here</h3>
            </div>

            <div className="row " data-aos="fade-left">
                {/* <!-- team img --> */}
                <div className="col-lg-12">
                    {/* <!-- team-img-slider --> */}
                   
                    {/* <div className="team-slider-active"> */}
                    <Slider {...settingsPictures} asNavFor={nav2} ref={slider => setSlider1(slider)}>
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage1} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage2} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage3} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage4} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage5} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage6} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        {/* <!-- single team img --> */}
                        <div className="team-img">
                            <img src={teamImage7} alt="" />
                        </div>
                        {/* <!-- single team img end --> */}
                        </Slider>
                    {/* </div> */}
                  
                   
                    {/* <!-- team content --> */}
                    <div className="team-content-active text-center">
                       <Slider {...settingsTitles} asNavFor={nav1} ref={slider => setSlider2(slider)} slidesToShow={1} swipeToSlide={true} focusOnSelect={true}>
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>John Betlehem</h6>
                            <p>Designer & Developer</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="single-portfolio.html" className="team-profile-view"><i className="fas fa-caret-right"></i>
                                view profile</a>
                        </div>
                        {/* <!-- single team content end --> */}
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>Rhici Abraham</h6>
                            <p>professional Designer</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="single-portfolio.html" className="team-profile-view"><i className="fas fa-caret-right"></i>
                                view profile</a>
                        </div>
                        {/* <!-- single team content end --> */}
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>John Doe</h6>
                            <p>professional Developer</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="#" className="team-profile-view"><i className="fas fa-caret-right"></i> view profile</a>
                        </div>
                        {/* <!-- single team content end --> */}
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>Mark Brone</h6>
                            <p>SEO Expert</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="single-portfolio.html" className="team-profile-view"><i className="fas fa-caret-right"></i>
                                view profile</a>
                        </div>
                        {/* <!-- single team content end --> */}
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>July Betlehem</h6>
                            <p>Web Developer</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="#" className="team-profile-view"><i className="fas fa-caret-right"></i> view profile</a>
                        </div>
                        {/* <!-- single team content end --> */}
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>John Betlehem</h6>
                            <p>Designer & Developer</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="single-portfolio.html" className="team-profile-view"><i className="fas fa-caret-right"></i>
                                view profile</a>
                        </div>
                        {/* <!-- single team content end --> */}
                        {/* <!-- single team content --> */}
                        <div className="single-team-content">
                            <h6>Axel Henry</h6>
                            <p>Graphics Designer</p>
                            {/* <!-- team icon --> */}
                            <div className="team-icon">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-google"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                            <a href="single-portfolio.html" className="team-profile-view"><i className="fas fa-caret-right"></i>
                                view profile</a>
                        </div>
                        </Slider>
                        {/* <!-- single team content end --> */}
                    </div>
                    
                </div>
                {/* <!-- team img end --> */}
              
            </div>
        </div>
    </section> 
    //{/* <!-- team area end --> */ }

  );
}

export const OurApproach = () => {

  return (
    <section className="our-approach pt-65 pb-60">
      <div className="container">
        <div className="section-title text-left " data-aos="fade-up">
          <h6>Our Approach</h6>
          <h3>Your needs Simplified.</h3>
          <p>
            In this day and age, so many in the food and dietary supplement manufacturing industry are being forced to do more with less; whether it be due to the pandemic or to supply chain shortages or other cost reduction measures. It can be tough to find and hire people with the right skills when you are trying to cut back. 
          </p>
          <p>
            One way around that is to gain access to the right skills without all the commitment and resources needed to attract and retain an experienced employee.  Hiring a highly educated and experienced consultant to do a free initial assessment of your plant and regulatory documentation to determine for you where your risks and vulnerabilities lie, and to provide solutions to you in a short period of time is just the answer to you need.
          </p>
          <p>
            You get the solutions for a price you can afford, in the short turn-around time you need it, no strings attached.
          </p>
        </div>
      </div>
    </section>
  );
}

export default TeamArea;