import React, { useState, useEffect, useContext } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement, 
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { LoadingContext } from '../components/LoadingContext';
import delIcon3 from '../assets/img/about/about-us-del-icon-3.png';

import "../assets/css/stripe.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentSucess = ({ amount, receipt_email, }) => {

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="about-us-single-det">
          <div className="single-det-icon">
            <img src={delIcon3} alt="" />
          </div>
          <h6>Thank you for your purchase.</h6>
          <p>A receipt in the amount of ${(amount / 100).toFixed(2)} has been sent to {receipt_email}.</p>
          <p>
            Should you have any questions regarding your registraion, please feel free to call us at 801-574-3949
          </p>
        </div>
      </div>
    </div>
  )
}

const PaymentError = ({charge, code, decline_code, message}) => {

  return(
    <div className="invalid-feedback d-block">{message}</div>
  )
}

const PaymentForm = ({productIds}) => {

  const loadingContext = useContext(LoadingContext);
  const [clientSecret, setClientSecret] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [emailAddress, setEmaiAddress] = useState('');

  const [invalidName, setInvalidName] = useState('');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [invalidCardNumber, setInvalidCardNumber] = useState('');
  const [invalidExpiration, setInvalidExpiration] = useState('');
  const [invalidCvc, setInvalidCvc] = useState('');

  const [displayPaymentForm, setDisplayPaymentForm] = useState(true);
  const [displayPaymentError, setDisplayPaymentError] = useState(null);
  const [displayPaymentSuccess, setDisplayPaymentSuccess] = useState(null);
  
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    console.log("productids: ", productIds);
    window
      .fetch("/api/stripe/createpaymentintent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({productIds: productIds})
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setClientSecret(data.clientSecret);
      });
  }, []);


  const submitPayment = async (e) => {
    e.preventDefault();
    console.log("Sending Payment");
    


    loadingContext.setIsLoading(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {

      receipt_email: emailAddress,
      payment_method: {
         //Knows how to get the other elements.
         card: elements.getElement(CardNumberElement),
         
         billing_details: {
           name : nameOnCard,
           email : emailAddress,
           //phone : phoneNumber,
           //address: {
            //line1 : billingAddress,
            //city : city,
            //state : state,
            //postal_code : zipCode 
           //}
         }
      }
    });

    if (payload.error) {
      loadingContext.setIsLoading(false);
      setDisplayPaymentError(payload.error);
    } else {
      loadingContext.setIsLoading(false);
      if (payload.paymentIntent.status == "succeeded") {
        setDisplayPaymentForm(false);
        setDisplayPaymentSuccess(payload.paymentIntent);
        
        //Sends Email regarding succesful registration
        fetch('/mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: nameOnCard,
            email: payload.paymentIntent.receipt_email,
            phonenumber: "N/A",
            subject: "Class Registration Purchased",
            message: `${nameOnCard} has registered for your class with an email of ${payload.paymentIntent.receipt_email}`
          })
        })
        .then(response => { }); //Empty not sure if i want to change this.
      }
    }
  }

  const checkName = (event) => {
    const name = event.target.value;
    if (!name) {
      setInvalidName('Please supply your name.')
    } else {
      setInvalidName('');
    }
  }

  const checkEmail = (event) => {
    const email = event.target.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setInvalidEmail('');
    } else {
      setInvalidEmail('Email Address is in incorrect format');
    }
  }


  const cardNumberChanged = (event) => {
    if (event.error) {
      setInvalidCardNumber(event.error.message);
    } else {
      setInvalidCardNumber('');
    }
  }

  const cardExpiryChanged = (event) => {
    if (event.error) {
      setInvalidExpiration(event.error.message);
    } else {
      setInvalidExpiration('');
    }
  }

  const cardCvcChanged = (event) => {
    if (event.error) {
      setInvalidCvc(event.error.message);
    } else {
      setInvalidCvc('');
    }
  }

  return (
    <>
      {displayPaymentForm &&
        <form onSubmit={submitPayment}>
          <div className="form-group">
            <label htmlFor="fullNameInput">Full Name</label>
            <input id="FullNameInput" type="text" className={invalidName ? 'form-control is-invalid' : 'form-control' } onChange={e => setNameOnCard(e.target.value)} onBlur={checkName} required />
            <div className={invalidName ? 'invalid-feedback d-block' : 'd-none'}>{invalidName}</div>
          </div>

          <div className="form-group">
            <label htmlFor="emailInput">Email Address</label>
            <input id="emailInput" type="email" className={invalidEmail ? 'form-control is-invalid' : 'form-control' } onChange={e => setEmaiAddress(e.target.value)} onBlur={checkEmail} />
            <div className={invalidEmail ? 'invalid-feedback d-block' : 'd-none'}>{invalidEmail}</div>
          </div>

          <div className="form-group">
            <label htmlFor="CardNumberInput">Card Number</label>
            <CardNumberElement id="CardNumberInput" className={invalidCardNumber ? 'form-control is-invalid' : 'form-control' } onChange={cardNumberChanged}/>
            <div className={invalidCardNumber ? 'invalid-feedback d-block' : 'd-none'}>{invalidCardNumber}</div>
          </div>

          <div className="form-group">
            <label htmlFor="ExpirationDateInput">Expiration Date</label>
            <CardExpiryElement id="ExpirationDateInput" className={invalidExpiration ? 'form-control is-invalid' : 'form-control' } onChange={cardExpiryChanged} />
            <div className={invalidExpiration ? 'invalid-feedback d-block' : 'd-none'}>{invalidExpiration}</div>
          </div>

          <div className="form-group">
            <label htmlFor="CVCInput">CVC</label>
            <CardCvcElement id="CVCInput" className={invalidCvc ? 'form-control is-invalid' : 'form-control' } onChange={cardCvcChanged} />
          </div>
          
          <button type="submit" className="btn btn-primary float-right mt-20" disabled={!stripe || !elements || invalidName || invalidEmail || invalidCardNumber || invalidExpiration || invalidCvc }>Make Payment</button>
          {displayPaymentError && <PaymentError { ...displayPaymentError }/>}
        </form>
      }
    
    {displayPaymentSuccess && <PaymentSucess { ...displayPaymentSuccess }/>}
    </>
  )
}


const RegistrationForm = ({ productIds }) => {

  return (
    <section className="pt-30 pb-30">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Elements stripe={stripePromise}>
              <PaymentForm productIds={productIds} />
            </Elements>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegistrationForm;