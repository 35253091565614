import react, { useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import mobileMenuBar  from '../assets/img/header/mobile-menu-bar.png';

const COLORS = {
  primaryDark: "#1F4A65",   
  primaryLight: "#fff",
};

const MobileMenuWrapper = styled.div`

  
  button {
    border: none;
    background: none;
  }
`;

const Navigation = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 600;
  width: ${(props) => (props.clicked ? "100%" : "0")};
  opacity: ${(props) => (props.clicked ? "1" : "0")};
  transition: width 0.8s, opacity 0.8s;
  background-color: ${COLORS.primaryDark};
  padding: 20px;

  button {
    position: absolute;
    right: 20px;
  }

  button:hover {
    color: ${ COLORS.primaryDark };
  }
`;

const List = styled.ul`
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;

const ItemLink = styled(NavLink)`
  display: block;
  font-size: 3rem;
  font-weight: 300;
  text-decoration: none;
  color: ${COLORS.primaryLight};
  padding: 1rem 2rem;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;
  &:hover,
  &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
    transform: translateX(1rem);
  }
`;


const MobileMenu = () => {

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <MobileMenuWrapper>
      <div className="sidebar-icon d-block d-md-none">
        <button id="open-sidebar" onClick={handleClick}><img src={mobileMenuBar} alt="" /></button>
      </div>
      
      <Navigation clicked={click}>
    
        <button className='btn btn-outline-light' onClick={handleClick}><i className="fas fa-window-close fa-3x"></i></button>
        
        <div className="row">
          <List>
            <li>
              <ItemLink onClick={handleClick} to={process.env.PUBLIC_URL + '/'}>Home</ItemLink>
            </li>
            <li>
              <ItemLink onClick={handleClick}  to={process.env.PUBLIC_URL + '/services'}>Services</ItemLink>
            </li>
            <li>
              <ItemLink onClick={handleClick}  to={process.env.PUBLIC_URL + '/training'}>Training</ItemLink>
            </li>
            {/* <li>
              <ItemLink onClick={handleClick} to="#contactus">Contact Us</ItemLink>
            </li> */}
          </List>
        </div>  
      </Navigation>
    </MobileMenuWrapper>
  );
}

export default MobileMenu;