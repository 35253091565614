import react from 'react';
import stripeImg from '../assets/img/footer/powered-by-stripe.webp';

const FooterArea = () => {

  return (
    // {/* <!-- footer area --> */}
    <footer>
        {/* <!-- footer wid area --> */}
        <div className="footer-wid-area pt-60 pb-50">
            <div className="container">
                <div className="row">
                    {/* <!-- single footer widget --> */}
                    <div className="col-md-4 col-lg-4">
                        <div className="single-footer-wid " data-aos="fade-right">
                            <div className="footer-title">
                                <h5>Contact Info</h5>
                            </div>
                            <div className="footer-info-content">
                                <ul>
                                    <li>West Jordan, UT 84081</li>
                                    <li>sayhello@fdyay.com</li>
                                    <li>1 (801) 574-3949</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- single footer wid end --> */}
                    {/* <!-- single footer widget --> */}
                    <div className="col-md-4 col-lg-4">
                        <div className="single-footer-wid " data-aos="fade-down">
                            <div className="footer-title">
                                <h5>Quicklinks</h5>
                            </div>
                            <div className="quick-link-wrapper">
                                <ul>
                                    <li><a href={process.env.PUBLIC_URL + '/services'}>Our Services</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/training'}>Training</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/privacy'}>Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <div className="single-footer-wid " data-aos="fade-left">
                            <div className="footer-title">
                                <h5>Secured By</h5>
                            </div>
                            <div className="quick-link-wrapper text-center">
                                <ul>
                                  <li><img src="https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png" /></li>
                                  <br />
                                  <li><img src={stripeImg} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- single footer widget --> */}
                    {/* <!-- single footer wid end --> */}
                </div>
            </div>
        </div>

        {/* <!-- footer copyright area --> */}
        <div className="footer-copyright ">
            <div className="container">
                <div className="row">
                    <div className="col-12 " data-aos="fade-down">
                        <p>Copyright 2022 © <span>FDYAY</span> | All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    // {/* <!-- footer area end --> */}
  );
}

export default FooterArea;